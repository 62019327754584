import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ siteTitle, activeLink }) => {

  function activeClass(linkName) {
    if (activeLink === linkName) return { className: 'nav-item active' };
    return { className: 'nav-item' };
  }

  return (

    <header>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <Link to='/' className="navbar-brand">
          <img src="/docs/4.5/assets/brand/bootstrap-solid.svg" width="30" height="30"
               className="d-inline-block align-top" alt="" loading="lazy"/>
          Torn Up Gaming
        </Link>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <Link to='/' {...activeClass('home')}>Home</Link>
            <Link to='/consulting' {...activeClass('consulting')}>Consulting</Link>
            <Link to='/projects' {...activeClass('projects')}>Projects</Link>
            <Link to='/skills' {...activeClass('skills')}>Skills</Link>
            <Link to='/contact' {...activeClass('contact')}>Contact</Link>
          </ul>
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
